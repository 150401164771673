//import React from 'react';
import './App.less';
import Weather from './Component/Weather';

function App() {
  return (
    <div className="main">      
      <Weather/>
    </div>
  );
}

export default App;
