const {
  WiDaySunny,
  WiCloudy,
  WiSnow,
  WiRain,
  WiStormShowers,
  WiDayFog
} = require("weather-icons-react");
let weather: string;
let icon: any;
const WeatherSituation = (code: number) => {
  switch (code) {
    case 0:
      weather = "Sunny";
      icon = WiDaySunny;
      break;
    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
      weather = "Rain";
      icon = WiRain;
      break;
    case 80:
    case 81:
    case 82:
      weather = "Rain showers";
      icon = WiStormShowers;
      break;
    case 85:
    case 86:
      weather = "Fog";
      icon = WiDayFog;
      break;
    case 71:
    case 73:
    case 75:
    case 77:
    case 80:
    case 81:
    case 82:
      weather = "Snow";
      icon = WiSnow;
      break;
    default:
      weather = "Clouds";
      icon = WiCloudy;
      break;
  }

  return [icon, weather];
};

export default WeatherSituation;
