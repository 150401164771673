import React from "react";
import getData from "../Service/WeatherApiService";
import WeatherSituation from "../Service/WeatherSituation";
import Navbar from "../Component/Navbar";
import TodayWeather from "./TodayWeather";
import DailyWeather from "./DailyWeather";
import moment from "moment";

const {WiDaySunny, WiCloudy, WiSnow, WiRain, WiStormShowers, WiDayFog} = require("weather-icons-react");

interface Props {}

interface State {}

class Weather extends React.Component<Props, State> {
  componentDidMount() {
    this.getWeatherData(this.state.currentCity);
  }

  state = {
    currentCity: "ottawa",
    currentWeather: 0,
    weatherCode: [],    
    dailyTemperatures: [],
    time: [],
    icon: WiCloudy,
    dailyIcon:WiCloudy,  
    weather: "",
  };   

  getWeatherData = (currentCity: string) => {      
    getData(currentCity)?.then((res) => {
      this.setState({
        currentWeather: Math.round(res.current_weather?.temperature),
      });
      this.setState({
        weatherCode: (res.daily?.weathercode).splice(1, 4),
      });
      this.setState({
        dailyTemperatures: res.daily?.temperature_2m_max,
        time: (res.daily?.time).splice(1, 4),
      });
      this.setState({ icon: WeatherSituation(this.state.weatherCode[0])[0] });
      this.setState({
        weather: WeatherSituation(this.state.weatherCode[0])[1],
      });     
    });
  };
  
  updateMyCounter = (currentCity: string) => {
    this.setState({ currentCity });
    this.getWeatherData(currentCity);     
  };

  render() {
    return (
      <>
        <Navbar updateParent={(currentCity) => this.updateMyCounter(currentCity)}/>        
        <div className="main-box">          
          <TodayWeather
            weather={this.state.weather}
            temp={this.state.currentWeather}
            icon={<this.state.icon className="today-icon" />}
          />
          <div className="small-box">
            {this.state.time.map((t, i) => {
              let Icon =this.state.dailyIcon 
               Icon = WeatherSituation(this.state.weatherCode[i])[0]                
              return (
                <DailyWeather
                  time={moment(this.state.time[i]).format("ddd")}                  
                  icon={<Icon className="daily-icon"/>}
                  temp={Math.round(this.state.dailyTemperatures[i])}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Weather;
