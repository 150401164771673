import React from "react";
 
const { WiDaySunny, WiCloudy, WiSnow, WiRain, WiStormShowers,WiDayFog} = require("weather-icons-react"); 

interface Props {
  time: string;
  icon: any;
  temp: number;
}

interface State {}

class DailyWeather extends React.Component<Props, State> {
  render() {
    const { time, icon, temp } = this.props;
    return (
      <div style={{ marginLeft: 0, WebkitBorderBottomLeftRadius: 5 }} className="test">
        <p> {time}</p>
        {icon}
        <div>
          <p>
            {temp}<span>0</span>
          </p>
        </div>
      </div>
    );
  }
}

export default DailyWeather;
