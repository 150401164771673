import React, { Component } from "react";

interface Props {
  updateParent(m: string): void;
}

interface State {}
const cities: string[] = ["OTTAWA", "MOSCOW", "TOKYO"];

class Navbar extends React.Component<Props, State> {
  
  state ={city:'OTTAWA'}

  setCity = async (selectedCity: string) => {      
    this.state.city = selectedCity.toUpperCase()   
    this.props.updateParent(selectedCity);  
  }
 

  
  render() {
    return (
      <nav className="navbar">
        <ul>
          {cities.map((city) => (
            <li onClick={() => this.setCity(city.toLowerCase())}>
              <span  
                onClick={() => this.setCity(city.toLowerCase())}
                className = {this.state.city === city ? `active-item` : `null` } 
              >
                {city}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}
export default Navbar;
