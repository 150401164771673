import React from "react";

const { WiDaySunny, WiCloudy } = require("weather-icons-react");

interface Props {
  temp: number;
  icon: any;
  weather: string;
}
interface State {}

class TodayWeather extends React.Component<Props, State> { 

  render() {
    return (
      <>
        <div className="boxes">
          <p>Today</p>
          <div className="today">
            {this.props.icon}
            <div className="tempreture">
              <p>
                {this.props.temp}
                <span className="today-temp">0</span>
              </p>
              <p>{this.props.weather}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TodayWeather;
