const getData = (city: string) => { 
 const weatherApi:any = {
    ottawa: "https://api.open-meteo.com/v1/forecast?latitude=45.41&longitude=-75.70&daily=weathercode,temperature_2m_max&current_weather=true&timezone=auto",
    moscow:"https://api.open-meteo.com/v1/forecast?latitude=55.75&longitude=37.62&daily=weathercode,temperature_2m_max&current_weather=true&timezone=Europe%2FMoscow",
    tokyo: "https://api.open-meteo.com/v1/forecast?latitude=35.69&longitude=139.69&daily=weathercode,temperature_2m_max&current_weather=true&timezone=Asia%2FTokyo"
  }
 
  try {
    return fetch(weatherApi[city]).then((res) => res.json());
  } catch (error) {
    console.log("error:", error);
  }
};

export default getData;
